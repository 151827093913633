import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const useForm = (initialForm, validateForm, handleSuccess) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const navigate = useNavigate()

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'pagina') {
      form.pagina = value
      setForm(form)
    }

    setForm({
      ...form,
      [name]: value
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const err = validateForm(form)

    if (err === null) {
      setLoading(true)

      if (form.pagina === 'FRIKKO' || form.pagina === 'HEATWAVE') {
        form.origen = 'WOOCOMMERCE'
        setForm(form)
      } else if (form.pagina === 'MERCADO LIBRE') {
        form.origen = 'ML'
        setForm(form)
      } else if (form.pagina === 'LIVERPOOL') {
        form.origen = 'LIVERPOOL'
        setForm(form)
      } else if (form.pagina === 'COPPEL') {
        form.origen = 'COPPEL'
        setForm(form)
      } else if (form.pagina === 'WALMART') {
        form.origen = 'WALMART'
        setForm(form)
      }

      axios
        .post(process.env.REACT_APP_BACKEND_URL + 'Orden/', form)
        .then(function (res) {
          res = res.data

          const devolver = {
            response: res,
            orden: form.numeroOrden
          }
          if (res.status === true) {
            const orden = res.data
            if (orden.existe === false) {
              return navigate('/mostrar-orden', { state: devolver })
            } else {
              const devolver2 = {
                status: true,
                data: { orden, id: form.numeroOrden }
              }
              setResponse(devolver2)
              handleSuccess()
            }
          } else {
            setResponse(res)
          }

          setTimeout(() => setLoading(false), 0)
        })
        .catch(function () {
          const res = {
            status: false,
            msg: 'Favor de intentarlo mas tarde'
          }
          setResponse(res)
          setTimeout(() => setResponse(null), 3000)
          setTimeout(() => setLoading(false), 0)
        })
    } else {
      setErrors(validateForm(form))
      setTimeout(() => setErrors({}), 3000)
    }
  }

  return {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  }
}
