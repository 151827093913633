import React, { useState, useEffect } from 'react'
import './BuscarOrden.css'
import Spinner from '../../helpers/spinner/spinner'
import { useForm } from '../../hooks/useFormOrden'
import { Modal } from '../../helpers/Modal/Modal'
import DatosEmail from './CorreoEnviar.jsx'

const initialForm = {
  numeroOrden: '',
  email: '',
  telefono: '',
  total: '',
  pagina: ''
}

function ordenEvent (event) {
  const tecla = event.key
  if (['.', 'e'].includes(tecla)) {
    event.preventDefault()
  }
}

function dontPaste (event) {
  event.preventDefault()
}

function priceEvent (event) {
  const tecla = event.key
  if (['e'].includes(tecla)) {
    event.preventDefault()
  }
}

const validationsForm = (form) => {
  const errors = {}
  let iserror = false
  const regexNumOrden = /^^\d{4,}$$/
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const regexTotal = /^[0-9]+(.[0-9]{1,2})?$/

  if (!form.numeroOrden.trim()) {
    errors.numeroOrden = "El campo 'Numero de Orden' es requerido"
    iserror = true
  } else if (!regexNumOrden.test(form.numeroOrden)) {
    errors.numeroOrden =
      "El campo 'Numero de Orden' debe de contener 4 caracteres o mas"
    iserror = true
  }

  if (!form.email.trim()) {
    errors.email = "El campo 'Correo Electrónico' es requerido"
    iserror = true
  } else if (!regexEmail.test(form.email)) {
    errors.email = "El campo 'Correo Electrónico' es incorrecto"
    iserror = true
  }

  if (!form.total.trim()) {
    errors.total = "El campo 'Total de la orden' es requerido"
    iserror = true
  } else if (!regexTotal.test(form.total)) {
    errors.total =
      "El campo 'Total de la orden' debe de contener los decimales de la compra"
    iserror = true
  }

  if (!form.pagina.trim()) {
    errors.pagina = "El campo 'Pagina de compra' es requerido"
    iserror = true
  }

  if (form.pagina.trim() === 'LIVERPOOL') {
    if (!form.telefono.trim()) {
      errors.telefono = "El campo 'Telefono' es requerido"
      iserror = true
    } else if (form.telefono.trim().length > 10) {
      errors.telefono = 'El numero de Telefono no es valido'
      iserror = true
    }
  }
  return iserror ? errors : null
}

const Facturacion = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleSubmit
  } = useForm(
    initialForm,
    validationsForm,
    handleSuccess)

  function handleSuccess () {
    setModalOpen(true)
  }
  useEffect(() => {
    document.title = 'Facturacion'
  }, [])
  return (
    <>
      <div className='buscador-page'>
        <div className='buscador-wrapper'>
          <div className='buscador-wrapper-heading'>
            <h2 className='buscador-heading'>Facturación {form.pagina}</h2>
          </div>
          <div className='container-form'>
            <form className='buscador-form' onSubmit={handleSubmit}>
              <label className='buscador-label' htmlFor='PrecioTotal'>
                Pagina de compra:
              </label>

              <select
                name='pagina'
                id='pagina'
                className='buscador-input'
                onChange={handleChange}
                defaultValue={''}
              >
                <option key={1} value='' disabled>
                  Selecciona el pagina de compra
                </option>
                <option key={2} value='FRIKKO'>
                  Frikko
                </option>
                <option key={3} value='HEATWAVE'>
                  Heatwave
                </option>
                <option key={4} value='MERCADO LIBRE'>
                  Mercado Libre
                </option>
                <option key={5} value='LIVERPOOL'>
                  Liverpool
                </option>
                <option key={6} value='COPPEL'>
                  Coppel
                </option>
                {/* <option key={7} value='WALMART'>
                  Walmart
                </option> */}
              </select>
              {errors.pagina && <p className='msg-error'>{errors.pagina}</p>}

              <label className='buscador-label' htmlFor='Numero de orden'>
                Numero de orden:
              </label>
              <input
                type={form.pagina === 'COPPEL' ? 'text' : 'number'}
                name='numeroOrden'
                id='numeroOrden'
                className='buscador-input'
                placeholder={form.pagina === 'COPPEL' ? 'Ej. 123456789-K, 123456789-B' : 'Ej. 4564, 20046876138679, 0400038421'}
                onChange={handleChange}
                value={form.numeroOrden}
                onKeyDown={ordenEvent}
                onPaste={dontPaste}
                step='any'
              />
              {errors.numeroOrden && (
                <p className='msg-error'>{errors.numeroOrden}</p>
              )}
              <label className='buscador-label' htmlFor='email'>
                Correo Electrónico:
              </label>

              <input
                type='text'
                name='email'
                id='email'
                className='buscador-input'
                onChange={handleChange}
                value={form.email}
                placeholder='Ej. correo@correo.com'
              />
              {errors.email && <p className='msg-error'>{errors.email}</p>}
              {/* form.pagina === 'WALMART' || */}
              {form.pagina === 'LIVERPOOL' || form.pagina === 'COPPEL'
                ? <>
                  <label className='buscador-label' htmlFor='Telefono'>
                    Telefono:
                  </label>

                  <input
                    type='number'
                    name='telefono'
                    id='telefono'
                    className='buscador-input number-input'
                    placeholder='Ej. 5555555555'
                    onChange={handleChange}
                    value={form.telefono}
                    onPaste={dontPaste}
                    onKeyDown={ordenEvent}
                    maxLength={10}
                  />
                  {errors.telefono && <p className='msg-error'>{errors.telefono}</p>}
                </>
                : <></>}

              <label className='buscador-label' htmlFor='PrecioTotal'>
                Total de la orden:
              </label>

              <input
                type='number'
                name='total'
                id='total'
                className='buscador-input number-input'
                placeholder='Ej. 5798.56'
                onChange={handleChange}
                value={form.total}
                onKeyDown={priceEvent}
              />
              {errors.total && <p className='msg-error'>{errors.total}</p>}
              <button
                className='buscador-button'
                type='submit'
                disabled={loading && true}
              >
                Buscar Orden
              </button>
              {response && response.status === false && (
                <p className='msg-error-final'>{response?.msg}</p>
              )}
            </form>
          </div>
          {loading === true && <Spinner />}
        </div>
      </div>
      {response && response.status === true && (
        <Modal
          ancho={600}
          header='FACTURA ENCONTRADA'
          isOpen={modalOpen}
          closeModal={() => setModalOpen(false)}
        >
          <DatosEmail orden={response.data}></DatosEmail>
        </Modal>
      )}
    </>
  )
}

export default Facturacion
